import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`🍄 `}<b>{`OKR`}</b>{` 🍄`}</p>
    <p>{`Objectives and Key Results (OKR) are a process for setting quarterly goals for your company.`}</p>
    <p>{`You want to be sure that everyone in the company is moving in the same direction and that is the main goal of OKRs.`}</p>
    <p>{`If you don’t know where are you going, you may never arrive to destination.`}</p>
    <p>{`Everyone should know what is expected from them at works. From the single individual to more complex team to the CEO of the company.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Here a list of resources about OKR:`}</p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://github.com/domenicosolazzo/awesome-okr"
        }}>{`Awesome ORKs`}</a></p>
    </blockquote>
    <p>{`This should be communicated in transparent way to the entire organization. That’s why OKRs are public and available to anyone in the organization.`}</p>
    <p>{`To recap, OKR system help with:`}</p>
    <ul>
      <li parentName="ul">{`What you need to do (and what you DON’T need to do).`}</li>
      <li parentName="ul">{`Keep the entire organization focus and moving towards what is important to you.`}</li>
      <li parentName="ul">{`Evaluate if the team is achieving their maximum potential.`}</li>
    </ul>
    <p>{`This is my favorite goal setting strategy and used for some years now. But I am convinced that it is not the only one.`}</p>
    <p>{`Which goal strategy do you use and why?`}</p>
    <p>{`Here a list of resources about OKR:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo/awesome-okr"
        }}>{`Awesome OKRs`}</a></li>
    </ul>
    <p>{`Enjoy!`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      